import { useEffect, useState, useContext } from "react";
import Request from './Request';
import REQUEST_STATS from './../classes/REQUEST_STATS';
import Loading from './Loading';

import {AppStateContext} from './AppStateProvider';

import API from "../classes/API";

var newRequest = {
    id: 83,
    firstname: "PHILIPP",
    lastname: "KKKK",
    company: "",
    status: "NEW",
    json: {
        groups: [
            {
               label: "Anschrift",
               pattern: "{street},{zipcode} {city}",
               fields:  {
                  street: {
                     label: "Straße",
                      value: "Palisadenstr 48"
                  },
                  city: {
                     label: "Stadt",
                      value: "Berlin"
                  },
                  zipcode: {
                     label: "Postleitzahl",
                      value: "10243"
                  }
               }
            },
            {
               label: "Persönliche Daten",
               pattern: "{name} {lastname}\n{birthdate}",
               fields:  {
                  name: {
                     label: "Name",
                      value: "Philipp"
                  },
                  lastname: {
                     label: "Nachname",
                      value: "Kamin"
                  },
                  birthdate: {
                     label: "Geburtstag",
                      value: "07.09.1992"
                  }
               }
            },
            {
               label: "Persönliche Daten",
               fields:  {
                  name: {
                     label: "Name",
                      value: "Philipp"
                  },
                  lastname: {
                     label: "Nachname",
                      value: "Kamin"
                  },
                  birthdate: {
                     label: "Geburtstag",
                      value: "07.09.1992"
                  }
               }
            }
         ]
    },
    phone: "0151-11924500",
    email: "carolin.wels@beurer.de",
    request_date: "2022-01-11 03:53:47",
    project: "Private",
    topic: "",
    history: [
        {
            date: "2022-01-11 03:53:47",
            id: 118,
            note: "",
            contactPerson: "",
            title: "Anfrage wurde eingereicht"
        }
    ],
    user: null,
    teamId: 3
};


function RequestFilter({requests, filterByProject, setFilterByProject, filterByStatus, setFilterByStatus, filterByUser, setFilterByUser,filterByTeam, setFilterByTeam, teams, users}) {
    const [isExpanded, setIsExpanded] = useState( false );
    var projects = [];

    var filteredUser = "";

    if( filterByUser !== "" ) {
        filteredUser = users && users.length > 0 ? users.filter(user => user.id === filterByUser)[0] : [] ;
    }

    for (var i = 0; i < requests.length; i++) {
        if (requests[i].project && !projects.includes(requests[i].project)) {
            projects.push(requests[i].project);
        }
    }

    if( isExpanded ) {
        return(
            <div className="we-requests__filter is--expanded">
                <button className="we-requests-filter-btn" onClick={() => setIsExpanded(!isExpanded)}>
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="17.536" height="16.527" viewBox="0 0 17.536 16.527">
                            <g id="arrow-down" transform="translate(1.768 1.277)">
                                <line id="Linie_163" data-name="Linie 163" y2="14" transform="translate(7 -0.027)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5"/>
                                <path id="Pfad_20" data-name="Pfad 20" d="M19,12l-7,7L5,12" transform="translate(-5 -5)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5"/>
                            </g>
                        </svg>
                        Filter:
                    </span>
                </button>
                <div className="we-filter__archive">
                    {/* Aktuell */}
                </div>
                <div>
                    <p className="h3">Projekt</p>
                    <ul className="we-nav__content">
                    <li>
                        <button
                        className={`we-btn${
                            filterByProject === "" ? " we-btn--accent" : ""
                        }`}
                        onClick={() => setFilterByProject("")}
                        >
                        Alle
                        </button>
                    </li>
                    {projects.map((project, index) => (
                        <li key={`FILTER_PROJECT_${index}`}>
                        <button
                            className={`we-btn${
                            filterByProject === project ? " we-btn--accent" : ""
                            }`}
                            onClick={() => setFilterByProject(project)}
                        >
                            {project}
                        </button>
                        </li>
                    ))}
                    </ul>
                </div>
                <div>
                    <p className="h3">Status</p>
                    <ul className="we-nav__content">
                        <li>
                            <button
                            className={`we-btn${
                                filterByStatus === "" ? " we-btn--accent" : ""
                            }`}
                            onClick={() => setFilterByStatus("")}
                            >
                            Alle
                            </button>
                        </li>
                        {Object.keys(REQUEST_STATS).map((key, index) => (
                            <li key={`FILTER_STATUS_${index}`}>
                            <button
                                className={`we-btn${
                                filterByStatus === key ? " we-btn--accent" : ""
                                }`}
                                onClick={() => setFilterByStatus(key)}
                            >
                                {REQUEST_STATS[key]}
                            </button>
                            </li>
                        ))}
                    </ul>
                </div>
                <div>
                    <p className="h3">Verantwortliche</p>
                    <ul className="we-nav__content">
                        <li>
                            <button
                            className={`we-btn${
                                filterByUser === "" ? " we-btn--accent" : ""
                            }`}
                            onClick={() => setFilterByUser("")}
                            >
                            Alle
                            </button>
                        </li>
                        {users.map((user, index) => (
                            <li key={`FILTER_STATUS_${index}`}>
                            <button
                                className={`we-btn${
                                filterByUser === user.id ? " we-btn--accent" : ""
                                }`}
                                onClick={() => setFilterByUser(user.id)}
                            >
                                {user.firstname ? user.firstname : ""}
                            </button>
                            </li>
                        ))}
                    </ul>
                </div>
                <div>
                    <p className="h3">Team</p>
                    <ul className="we-nav__content">
                        <li>
                            <button
                            className={`we-btn${
                                filterByTeam === "" ? " we-btn--accent" : ""
                            }`}
                            onClick={() => setFilterByTeam("")}
                            >
                            Alle
                            </button>
                        </li>
                        {teams.map((team, index) => (
                            <li key={`FILTER_STATUS_${index}`}>
                            <button
                                className={`we-btn${
                                    filterByTeam === team.id ? " we-btn--accent" : ""
                                }`}
                                onClick={() => setFilterByTeam(team.id)}
                            >
                                {team.name ? team.name : ""}
                            </button>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        )
    } else {
        return(
            <div className="we-requests__filter">
                 <button className="we-requests-filter-btn" onClick={() => setIsExpanded(!isExpanded)}>
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="17.536" height="16.527" viewBox="0 0 17.536 16.527">
                            <g id="arrow-down" transform="translate(1.768 1.277)">
                                <line id="Linie_163" data-name="Linie 163" y2="14" transform="translate(7 -0.027)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5"/>
                                <path id="Pfad_20" data-name="Pfad 20" d="M19,12l-7,7L5,12" transform="translate(-5 -5)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5"/>
                            </g>
                        </svg>
                        Filter:
                    </span>
                </button>
                <div className="we-filter__archive">
                    {/* Aktuell */}
                </div>
                <div>
                    {filterByProject === "" ?
                    <button
                        className="we-btn we-btn--accent"
                        onClick={() => setFilterByProject("")}
                        disabled
                        >
                        Alle
                    </button> 
                    : 
                    <button className="we-btn we-btn--accent" disabled>{projects.filter(project => project === filterByProject)[0]}</button>
                    }
                </div>
                <div>
                    {filterByStatus === "" ?
                    <button
                        className="we-btn we-btn--accent"
                        onClick={() => setFilterByStatus("")}
                        disabled
                        >
                        Alle
                    </button> 
                    : 
                    <button className="we-btn we-btn--accent" disabled>{REQUEST_STATS[Object.keys(REQUEST_STATS).filter(status => status === filterByStatus)[0]]}</button>
                    }
                </div>
                <div>
                    {(filterByUser === "" && filteredUser === "") ?
                        <button
                            className="we-btn we-btn--accent"
                            onClick={() => setFilterByUser("")}
                            disabled
                            >
                            Alle
                        </button> 
                        : 
                        <button className="we-btn we-btn--accent" disabled>{(filteredUser && filteredUser.firstname) ? filteredUser.firstname : ""}</button>
                    }
                </div>
                <div>
                    {filterByTeam === "" ?
                        <button
                            className="we-btn we-btn--accent"
                            onClick={() => setFilterByUser("")}
                            disabled
                            >
                            Alle
                        </button> 
                        : 
                        <button className="we-btn we-btn--accent" disabled>{teams.filter(team => team.id === filterByTeam)[0].name}</button>
                    }
                </div>
            </div>
        )
    }
    
}

export default function Requests({user}) {
    //TODO Loading implementation
    const [isLoading, setIsLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [teams, setTeams] = useState([]);
    const [requests, setRequests] = useState([]);
    const [filterByProject, setFilterByProject] = useState("");
    const [filterByStatus, setFilterByStatus] = useState("");
    const [filterByTeam, setFilterByTeam] = useState("");
    const [filterByUser, setFilterByUser] = useState(user.id ? user.id : "");
    const {isModalOpen} = useContext(AppStateContext);


    var filteredRequests = requests;
    var projects = [];

  
      const selectRequestStatusHandler = (e, request) => {
        var value = e.target.value;
        var updatedRequest = requests.map((req) => {
          if (req === request) {
            req.status = value;
            API.updateRequestStatus(user.token, req);
          }
          return req;
        });
    
        setRequests(updatedRequest);
      };
      
      const updateRequest = (updatedRequest) => {
        var updatedRequests = requests.map((request) => {
          if (request.id === updatedRequest.id) {
            request = updatedRequest;
          }
          return request;
        });
        setRequests(updatedRequests);
      }

      function deleteRequestHandler(requestId) {
        if( window.confirm("Möchten Sie diesen Anfrage wirklich löschen?") ) {
            API.deleteRequest(user.token, requestId).then(response => {
                if( response.success ) {
                    var updatedRequests = requests.filter(request => request.id !== requestId);
                    setRequests( updatedRequests );
                }
            });
        }
    }
  

    for (var i = 0; i < requests.length; i++) {
        if (requests[i].project && !projects.includes(requests[i].project)) {
        projects.push(requests[i].project);
        }
    }

    useEffect(function () {
        API.getRequests(user.token, filterByTeam, filterByUser, filterByStatus, filterByProject).then(response => {
            if (response.success) {
                setRequests(response.requests);
            }
        });
        if( isLoading ) {
          
          API.getUsers(user.token).then(response => {
            if (response.success) {
                setUsers(response.users);
            }
          });
          API.getTeams(user.token).then(response => {
            if (response.success) {
                setTeams(response.teams);
            }
          });

          setIsLoading(false);
        }
    }, [isLoading, filterByProject, filterByStatus, filterByTeam, filterByUser]);
/*
    filteredRequests = filteredRequests.filter((request) => {
        if (request.project && request.project.includes(filterByProject)) {
          if (request.status && request.status.includes(filterByStatus)) {
            if (request.user === null || request.user && request.user.id && request.user.id.toString().includes( filterByUser) ) {
                return request;
            }
          }
        }
    });

    if( filterByTeam !== "" ) {
        filteredRequests = filteredRequests.filter((request) => request.teamId && parseInt(request.teamId) === parseInt(filterByTeam));
    }


    if( filterByStatus === "" && (filterByStatus !== "CLOSED" && filterByStatus !== "ABORTED") ) {
        filteredRequests = filteredRequests.filter(request => request.status !==  "CLOSED" && request.status !== "ABORTED" );
    }
    */
    if( isLoading ) {
        return( <Loading />);
    }
    
    return(
        <div className={`we-requests${isModalOpen ? " is--blurred" : ""}`}>
            <RequestFilter requests={requests} filterByProject={filterByProject} setFilterByProject={setFilterByProject} filterByStatus={filterByStatus} setFilterByStatus={setFilterByStatus} filterByUser={filterByUser} setFilterByUser={setFilterByUser} filterByTeam={filterByTeam} setFilterByTeam={setFilterByTeam} teams={teams} users={users} />
            {filteredRequests.length > 0 ? 
            <ul className="we-requests__list">
                {filteredRequests.map((request, index) => <Request request={request} index={index} key={`REQUEST_LIST_ITEM_${index}`} users={users} teams={teams} selectRequestStatusHandler={selectRequestStatusHandler} user={user} updateRequest={updateRequest} deleteRequestHandler={deleteRequestHandler} />)}
            </ul>
            : <ul><li className="we-request" key={`REQUEST_ITEM_0`}><div></div>Keinen Eintrag gefunden</li></ul>}
        </div>
    )
}