
export default function Footer() {
    return(
        <footer className="we-footer">
            <p>
                Designed by W&S Epic<br/>
                Assembled in Germany
            </p>
            <svg xmlns="http://www.w3.org/2000/svg" width="53.32" height="53.319" viewBox="0 0 53.32 53.319">
                <path id="Pfad_242" data-name="Pfad 242" d="M114.114,173.868a26.659,26.659,0,1,0,26.66,26.659,26.659,26.659,0,0,0-26.66-26.659m.893,37.55h-4.115a.658.658,0,0,1-.729-.526L106.779,197.7l-3.414,13.188a.646.646,0,0,1-.7.526H98.55a.606.606,0,0,1-.642-.526l-4.7-19.374c-.087-.321,0-.526.321-.526h3.735a.6.6,0,0,1,.671.526l2.918,12.984,3.443-13.13a.473.473,0,0,1,.5-.38h3.939a.5.5,0,0,1,.526.38l3.414,13.13,2.947-12.984c.087-.321.2-.526.642-.526h3.7c.351,0,.438.2.351.526l-4.7,19.374a.6.6,0,0,1-.613.526m19.855-19.4a.338.338,0,0,1-.374.292H124.129l-1.95,8.258h9.338c.175,0,.255.087.209.291l-.143.7a.387.387,0,0,1-.4.292h-9.338l-1.95,8.258h10.36c.2,0,.255.087.209.291l-.175.729c-.016.2-.141.292-.344.292H118.56c-.2,0-.284-.087-.239-.292l4.752-19.841c.016-.2.141-.292.374-.292H134.8c.2,0,.285.087.24.292Z" transform="translate(-87.454 -173.868)" fill="#6d6d77"/>
            </svg>
        </footer>
    )
}