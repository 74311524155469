import React, { useState } from "react";

export const AppStateContext = React.createContext(null);

export default function AppStateProvider({ children }) {
    const [snackbars, setSnackbars] = useState([]);
    const [snackbarsVisible, setSnackbarsVisible] = useState( snackbars.length > 0 );
    const [isModalOpen, setIsModalOpen] = useState( false );
    
 
    /** @function addSnackbar */
    // the above is the same as:
    /** @function
     * @name addSnackbar 
     * @param {string} title - The title of the book.
     * @param {string} message - The author of the book.
     * */
    const addSnackbar = (title = "", message = "", type = "", link = null, linkTitle = "", declineText = "") => {
        var newSnackbars = [...snackbars];
        newSnackbars.unshift( { id: (snackbars.length + 1), title: title, message: message, type: type, link: link, linkTitle: linkTitle, declineText: declineText } )
        setSnackbars(newSnackbars, setSnackbarsVisible( newSnackbars.length > 0 ) );
    }

    const closeSnackbar = (snackbarID) => {
        var newSnackbars = snackbars.filter(snackbar => snackbar.id !== snackbarID);
        setSnackbars(newSnackbars, setSnackbarsVisible( newSnackbars.length > 0 ));
    }

    const toggleModal = (isOpen = false ) => {
        setIsModalOpen( isOpen );
    }

    const state = {
        AddSnackbar: addSnackbar,
        snackbarsVisible: snackbarsVisible,
        Snackbars: snackbars,
        CloseSnackbar: closeSnackbar,
        isModalOpen: isModalOpen,
        ToggleModal: toggleModal
    };

    return (
        <AppStateContext.Provider value={state}>
            {children}
        </AppStateContext.Provider>
    );
}
