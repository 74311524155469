import React, { useState } from "react";
import { GoogleLogin } from 'react-google-login';
import API from '../classes/API';
import logo from './../assets/icons/wse-logo.svg'; // Tell webpack this JS file uses this image

import { AppStateContext } from "./AppStateProvider";

const CLIENT_ID = "455752339862-4tcgkp2si8a0h5e5tb2q2dc2oo6uun2d.apps.googleusercontent.com";
const GOOGLE_LOGIN_AVAILABLE = true;

export default function Login({loginHandler}) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loginWithUsername, setLoginWithUsername] = useState( false );
    // { AddSnackbar } = useContext(AppStateContext);

    // AkpUP19

    const _submitHandler = (e) => {
        e.preventDefault();
        if( username.trim() === "") { window.alert("Hoppla! ⚠️", "Die eingegebenen Benutzerdaten sind nicht korrekt.", "warning"); return; }
        if( password.trim() === "") { window.alert("Hoppla! ⚠️", "Die eingegebenen Benutzerdaten sind nicht korrekt.", "warning");; return; }
        userLogin( username, password );
    }

    const userLogin = (username, password) => {
        API.userLogin(username, password, "", false).then(response => {
            if( response.success ) {
                loginHandler( response );
            } else {
                window.alert("Hoppla! ⚠️", "Die eingegebenen Benutzerdaten sind nicht korrekt.", "warning");
            }
        });
    }

    const responseGoogleSuccess = (response) => {
        API.userLogin("", "", response.tokenId, true).then(response => {
            if( response.success ) {
                if( response.authURL ) {
                    loginHandler( response, window.open(response.authURL) );
                } else {
                    loginHandler( response );
                }
            } else {

            }
            
        });
    }

    const responseGoogleFailure = (response) => {
        window.alert("Hoppla! ⚠️", "Da ist wohl etwas schief gelaufen.", "warning")
    }

    if( GOOGLE_LOGIN_AVAILABLE === false ) {
        return(
            <>
            <div className="we-login">
            <svg className="we-login__logo" version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 898.1 227.5" style={{enableBackground:"new 0 0 898.1 227.5"}}>
                <g>
                    <path d="M137.5,170.8c-3.2,0-5-1.4-5.7-4.1L105.6,64.3L79.1,166.8c-0.7,2.7-2.5,4.1-5.4,4.1H41.7c-2.7,0-4.3-1.4-5-4.1L0.2,16.3
                        c-0.7-2.5,0-4.1,2.5-4.1h29c3.4,0,4.5,1.6,5.2,4.1l22.7,100.8l26.7-102c0.7-2,1.8-2.9,3.9-2.9h30.6c2,0,3.4,0.9,4.1,2.9l26.5,102
                        l22.9-100.8c0.7-2.5,1.6-4.1,5-4.1H208c2.7,0,3.4,1.6,2.7,4.1l-36.5,150.4c-0.7,2.7-2.3,4.1-4.8,4.1H137.5z"></path>
                    <path d="M308.9,109.2l16.8-14.3c1.1-0.9,2.5-1.6,3.6-1.6s2.3,0.7,3.4,1.8l13.4,15.2c2.5,2.5,2.7,5,0.5,7.2l-16.3,14.7l21.1,23.1
                        c2.7,2.7,2.5,4.8-0.2,7.7l-12.5,12c-1.4,1.1-2.7,1.8-4.1,1.8c-1.6,0-2.9-0.7-4.5-2l-22-24.2c-19.9,14-34,22.4-56.6,22.4
                        c-26.1,0-45.1-18.6-45.1-43c0-22.2,13.4-34.9,37.4-49.2c-9.7-12.5-14.5-26.3-12-39c4.3-20.2,22.7-31.9,40.1-31.9
                        c14.5,0,27.6,6.8,36.7,18.4c1.6,2,1.4,3.6-0.7,5.2l-15.6,12.9c-0.9,0.7-1.6,1.1-2.5,1.1s-1.8-0.5-2.7-1.4
                        c-3.9-3.9-7.2-6.3-12.5-6.3c-6.3,0-11.1,4.1-11.8,10.4c-0.9,5.7,3.4,12,9.5,18.6L308.9,109.2z M286,126.9l-22.4-24.7
                        c-15.6,9.1-24,13.1-24,24.7c0,8.2,6.8,14.3,15.6,14.3C267,141.1,276,135.7,286,126.9z"></path>
                    <path d="M450.4,49.6c-0.9,0.9-2.3,1.8-3.6,1.8c-0.9,0-1.8-0.2-2.7-0.9c-7.5-4.5-16.5-8.4-25.4-8.4c-9.7,0-17.7,5-17.7,12.9
                        c0,23.1,70.2,14.7,70.2,70c0,21.1-15.6,48-54.6,48c-20.2,0-36.2-7.5-50.8-18.6c-1.8-1.6-2.9-2.9-1.4-5.4l13.1-19.9
                        c0.7-1.1,1.8-1.8,3.4-1.8c0.7,0,1.8,0.2,2.7,0.9c9.1,7,22.4,12.7,33.3,12.7c13.8,0,20.4-7,20.4-15.4c0-25.6-70.5-16.1-70.5-70.2
                        c0-27.2,23.6-45.3,53.2-45.3c16.3,0,29.7,4.5,42.6,12.7c2.3,1.6,2.7,3.4,1.4,5.7L450.4,49.6z"></path>
                </g>
                <g>
                    <path d="M614.6,12.2c1.6,0,2.3,0.7,2,2.3l-0.9,5.7c-0.2,1.6-1.1,2.3-2.7,2.3h-80.4l-10,64.1h72.5c1.4,0,2,0.7,1.8,2.3l-0.7,5.4
                        c-0.5,1.6-1.4,2.3-2.9,2.3h-72.5l-10,64.1h80.4c1.6,0,2,0.7,1.8,2.3l-0.9,5.7c0,1.6-0.9,2.3-2.5,2.3h-88.4c-1.6,0-2.3-0.7-2-2.3
                        l24.5-154.1c0-1.6,0.9-2.3,2.7-2.3H614.6z"></path>
                    <path d="M637.7,57.5c1.6,0,2.3,0.7,2,2.3l-3.9,23.3c12-17,29.9-27.9,52.3-27.9c29.9,0,47.1,23.3,47.1,50.8
                        c0,36.5-29,67.1-65.2,67.1c-23.1,0-37.8-13.6-43.7-29l-6.1,37.8c-2.9,19.3-6.1,39.9-6.8,43.3c-0.2,1.6-1.1,2.3-2.7,2.3h-5.7
                        c-1.4,0-2-0.7-1.8-2.3l26.3-165.4c0.2-1.6,0.9-2.3,2.5-2.3H637.7z M685.5,65.2c-27.2,0-50.1,21.8-54.4,49.2
                        c-4.3,27,12.2,48.7,39.4,48.7c30.8,0,54.6-26.7,54.6-56.9C725.1,83.6,710.2,65.2,685.5,65.2z"></path>
                    <path d="M747.8,170.8c-1.6,0-2.3-0.7-2-2.3l17.4-108.8c0.2-1.6,0.9-2.3,2.5-2.3h5.7c1.6,0,2.3,0.7,2,2.3L756,168.6
                        c-0.2,1.6-0.9,2.3-2.5,2.3H747.8z M775.9,0c4.8,0,8.4,3.6,8.4,8.4c0,4.5-3.6,7.9-8.4,7.9c-4.5,0-8.2-3.4-8.2-7.9
                        C767.7,3.6,771.4,0,775.9,0z"></path>
                    <path d="M866.9,160c0.9,1.8,0.2,2.5-1.4,3.6c-8.4,5.2-20.2,9.5-33.8,9.5c-29.9,0-48-22.7-48-50.8c0-36.7,29.7-67.1,66.8-67.1
                        c13.6,0,23.3,4.5,29.9,9.7c1.6,1.1,1.8,2.3,0.7,3.6l-3.6,4.1c-0.7,0.7-1.4,0.9-2,0.9s-1.1,0-1.6-0.5c-10.2-7.2-20.6-7.9-24.9-7.9
                        c-30.6,0-55.1,26.7-55.1,56.9c0,22.7,14.3,41,39.6,41c10.6,0,19.7-3.2,27.6-7.7c0.7-0.5,1.4-0.9,1.8-0.9c0.7,0,1.1,0.5,1.6,1.4
                        L866.9,160z"></path>
                    <path d="M889.9,156.8c4.5,0,8.2,3.6,8.2,8.2c0,4.8-3.6,8.2-8.2,8.2c-4.8,0-8.2-3.4-8.2-8.2C881.7,160.4,885.1,156.8,889.9,156.8z"></path>
                </g>
            </svg>
            <form onSubmit={(e) => _submitHandler(e)}>
                <h2>Login</h2>
                <p>&nbsp;</p>
                <input 
                    type="text"
                    className="we-input"
                    value={username}
                    placeholder="Benutzername"
                    onChange={e => setUsername(e.target.value)}
                />
                <input 
                    type="password"
                    className="we-input"
                    value={password}
                    placeholder="Passwort"
                    onChange={e => setPassword(e.target.value)}
                />
                <button className="we-btn" type="submit">Anmelden</button>
            </form>
            </div>
            </>
        )
    }


    return(
        <div className="we-login">
            <svg className="we-login__logo" version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 898.1 227.5" style={{enableBackground:"new 0 0 898.1 227.5"}}>
                <g>
                    <path d="M137.5,170.8c-3.2,0-5-1.4-5.7-4.1L105.6,64.3L79.1,166.8c-0.7,2.7-2.5,4.1-5.4,4.1H41.7c-2.7,0-4.3-1.4-5-4.1L0.2,16.3
                        c-0.7-2.5,0-4.1,2.5-4.1h29c3.4,0,4.5,1.6,5.2,4.1l22.7,100.8l26.7-102c0.7-2,1.8-2.9,3.9-2.9h30.6c2,0,3.4,0.9,4.1,2.9l26.5,102
                        l22.9-100.8c0.7-2.5,1.6-4.1,5-4.1H208c2.7,0,3.4,1.6,2.7,4.1l-36.5,150.4c-0.7,2.7-2.3,4.1-4.8,4.1H137.5z"></path>
                    <path d="M308.9,109.2l16.8-14.3c1.1-0.9,2.5-1.6,3.6-1.6s2.3,0.7,3.4,1.8l13.4,15.2c2.5,2.5,2.7,5,0.5,7.2l-16.3,14.7l21.1,23.1
                        c2.7,2.7,2.5,4.8-0.2,7.7l-12.5,12c-1.4,1.1-2.7,1.8-4.1,1.8c-1.6,0-2.9-0.7-4.5-2l-22-24.2c-19.9,14-34,22.4-56.6,22.4
                        c-26.1,0-45.1-18.6-45.1-43c0-22.2,13.4-34.9,37.4-49.2c-9.7-12.5-14.5-26.3-12-39c4.3-20.2,22.7-31.9,40.1-31.9
                        c14.5,0,27.6,6.8,36.7,18.4c1.6,2,1.4,3.6-0.7,5.2l-15.6,12.9c-0.9,0.7-1.6,1.1-2.5,1.1s-1.8-0.5-2.7-1.4
                        c-3.9-3.9-7.2-6.3-12.5-6.3c-6.3,0-11.1,4.1-11.8,10.4c-0.9,5.7,3.4,12,9.5,18.6L308.9,109.2z M286,126.9l-22.4-24.7
                        c-15.6,9.1-24,13.1-24,24.7c0,8.2,6.8,14.3,15.6,14.3C267,141.1,276,135.7,286,126.9z"></path>
                    <path d="M450.4,49.6c-0.9,0.9-2.3,1.8-3.6,1.8c-0.9,0-1.8-0.2-2.7-0.9c-7.5-4.5-16.5-8.4-25.4-8.4c-9.7,0-17.7,5-17.7,12.9
                        c0,23.1,70.2,14.7,70.2,70c0,21.1-15.6,48-54.6,48c-20.2,0-36.2-7.5-50.8-18.6c-1.8-1.6-2.9-2.9-1.4-5.4l13.1-19.9
                        c0.7-1.1,1.8-1.8,3.4-1.8c0.7,0,1.8,0.2,2.7,0.9c9.1,7,22.4,12.7,33.3,12.7c13.8,0,20.4-7,20.4-15.4c0-25.6-70.5-16.1-70.5-70.2
                        c0-27.2,23.6-45.3,53.2-45.3c16.3,0,29.7,4.5,42.6,12.7c2.3,1.6,2.7,3.4,1.4,5.7L450.4,49.6z"></path>
                </g>
                <g>
                    <path d="M614.6,12.2c1.6,0,2.3,0.7,2,2.3l-0.9,5.7c-0.2,1.6-1.1,2.3-2.7,2.3h-80.4l-10,64.1h72.5c1.4,0,2,0.7,1.8,2.3l-0.7,5.4
                        c-0.5,1.6-1.4,2.3-2.9,2.3h-72.5l-10,64.1h80.4c1.6,0,2,0.7,1.8,2.3l-0.9,5.7c0,1.6-0.9,2.3-2.5,2.3h-88.4c-1.6,0-2.3-0.7-2-2.3
                        l24.5-154.1c0-1.6,0.9-2.3,2.7-2.3H614.6z"></path>
                    <path d="M637.7,57.5c1.6,0,2.3,0.7,2,2.3l-3.9,23.3c12-17,29.9-27.9,52.3-27.9c29.9,0,47.1,23.3,47.1,50.8
                        c0,36.5-29,67.1-65.2,67.1c-23.1,0-37.8-13.6-43.7-29l-6.1,37.8c-2.9,19.3-6.1,39.9-6.8,43.3c-0.2,1.6-1.1,2.3-2.7,2.3h-5.7
                        c-1.4,0-2-0.7-1.8-2.3l26.3-165.4c0.2-1.6,0.9-2.3,2.5-2.3H637.7z M685.5,65.2c-27.2,0-50.1,21.8-54.4,49.2
                        c-4.3,27,12.2,48.7,39.4,48.7c30.8,0,54.6-26.7,54.6-56.9C725.1,83.6,710.2,65.2,685.5,65.2z"></path>
                    <path d="M747.8,170.8c-1.6,0-2.3-0.7-2-2.3l17.4-108.8c0.2-1.6,0.9-2.3,2.5-2.3h5.7c1.6,0,2.3,0.7,2,2.3L756,168.6
                        c-0.2,1.6-0.9,2.3-2.5,2.3H747.8z M775.9,0c4.8,0,8.4,3.6,8.4,8.4c0,4.5-3.6,7.9-8.4,7.9c-4.5,0-8.2-3.4-8.2-7.9
                        C767.7,3.6,771.4,0,775.9,0z"></path>
                    <path d="M866.9,160c0.9,1.8,0.2,2.5-1.4,3.6c-8.4,5.2-20.2,9.5-33.8,9.5c-29.9,0-48-22.7-48-50.8c0-36.7,29.7-67.1,66.8-67.1
                        c13.6,0,23.3,4.5,29.9,9.7c1.6,1.1,1.8,2.3,0.7,3.6l-3.6,4.1c-0.7,0.7-1.4,0.9-2,0.9s-1.1,0-1.6-0.5c-10.2-7.2-20.6-7.9-24.9-7.9
                        c-30.6,0-55.1,26.7-55.1,56.9c0,22.7,14.3,41,39.6,41c10.6,0,19.7-3.2,27.6-7.7c0.7-0.5,1.4-0.9,1.8-0.9c0.7,0,1.1,0.5,1.6,1.4
                        L866.9,160z"></path>
                    <path d="M889.9,156.8c4.5,0,8.2,3.6,8.2,8.2c0,4.8-3.6,8.2-8.2,8.2c-4.8,0-8.2-3.4-8.2-8.2C881.7,160.4,885.1,156.8,889.9,156.8z"></path>
                </g>
            </svg>
            {!loginWithUsername ?
                <>
                    <h2>Hi <span role="img" aria-label="Wink Emoji">👋🏻</span></h2>
                    <p>Wie m&ouml;chtest du dich anmelden?</p>
                    <button className="we-btn small" onClick={() => setLoginWithUsername( true )}>Mit Benutzername anmelden</button>
                </>
                : ""
            }
            {!loginWithUsername ? 
            <GoogleLogin
                clientId={CLIENT_ID}
                buttonText="Mit Google Anmelden"
                onSuccess={responseGoogleSuccess}
                onFailure={responseGoogleFailure}
                cookiePolicy={'single_host_origin'}
                className="we-btn small"
            />
            : ""
            }
            {loginWithUsername ?
                <>
                    <form onSubmit={(e) => _submitHandler(e)}>
                        <h2>Login</h2>
                        <p>&nbsp;</p>
                        <input 
                            type="text"
                            className="we-input"
                            value={username}
                            placeholder="Benutzername"
                            onChange={e => setUsername(e.target.value)}
                        />
                        <input 
                            type="password"
                            className="we-input"
                            value={password}
                            placeholder="Passwort"
                            onChange={e => setPassword(e.target.value)}
                        />
                        <button className="we-btn" type="submit">Anmelden</button>
                    </form>
                    <button className="we-a" onClick={() => setLoginWithUsername( false ) }>Andere Anmelde-Methode</button>
                </>
            : ""
            }
        </div>
    )
}
