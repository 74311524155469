import { useState, useContext, useRef } from "react";
import REQUEST_STATS from './../classes/REQUEST_STATS';
import DropdownMenu from "./DropdownMenu";

import RequestPropertyGroup from './RequestPropertyGroup';

import { AppStateContext } from './AppStateProvider';

import API from "../classes/API";
import WeSelect2 from "./WeSelect2";
const DEBUG = true;

function RequestHistory({user, request, isExpanded, updateRequest, currentDate, setCurrentDate, getCurrentDateTime}) {
    const [selectedHistoryEntry, setSelectedHistoryEntry] = useState( null );
    const [contactPerson, setContactPerson] = useState(user.firstname ? user.firstname : "");
    const [historyEntries, setHistoryEntries] = useState((request.history && request.history.length > 0) ? request.history : []);
    const [title, setTitle] = useState("");
    const [note, setNote] = useState("");
    var historyEntryDetail = "";
    const { ToggleModal } = useContext(AppStateContext);

    const formatDate = (date) => {
        if (date.split(" ").length <= 1) {
          date = date.split("T");
        } else {
          date = date.split(" ");
        }
        date[0] = date[0].split("-").reverse().join(".");
        date[1] = date[1].split(":");
        if (date[1].length > 2) {
          date[1].pop();
        }
        date[1] = date[1].join(":");
        return date.join(" ");
    };

    const handleDeleteHistoryEntry = (historId ) => {
        if( window.confirm( "Möchtest du diesen Eintrag wirklich löschen?") ) {
            API.deleteHistory(user.token, historId).then(response => {
                if(response.success) {
                    setHistoryEntries(historyEntries.filter(history => history.id !== historId ) );
                }
            });
        }
    }

    const addNewHistoryEntry = (e) => {
        e.preventDefault();
        API.addNewRequestHistoryEntry(user.token, request.id, currentDate, contactPerson, title, note).then(response => {
            if( response.success ) {
                if (response.historyEntry) {
                    request.history.push(response.historyEntry);
                    setCurrentDate( getCurrentDateTime(),
                        setContactPerson("",
                            setTitle("", 
                                setNote("",
                                    setHistoryEntries( request.history,
                                        updateRequest( request )
                                    )
                                )
                            )
                        )
                    );
                }
            }
        });
    }

    const dayDifferenceBetweenDates  = (date) => {
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
          // Discard the time and time-zone information.
        const date1 = new Date(date);
        const date2 = new Date();
        const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
        const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());

        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    }

    if( selectedHistoryEntry ) {
        historyEntryDetail = <div className="we-request-history-modal">
            <div className="we-request-history-modal__head">
                <p>Nachricht von {selectedHistoryEntry.contactPerson ? selectedHistoryEntry.contactPerson : "-"}</p>
                <button onClick={() => setSelectedHistoryEntry(null, ToggleModal( false ))}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="13.435" height="13.435" viewBox="0 0 13.435 13.435">
                        <g id="Gruppe_109" data-name="Gruppe 109" transform="translate(-2.357 6.6) rotate(-45)">
                            <line id="Linie_200" data-name="Linie 200" y2="13" transform="translate(6.333)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="3"/>
                            <line id="Linie_201" data-name="Linie 201" x2="12.667" transform="translate(0 6.5)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="3"/>
                        </g>
                    </svg>
                </button>
            </div>
            <div className="we-request-history-modal__body">
                <p>{selectedHistoryEntry.note ? selectedHistoryEntry.note : "-"}</p>
            </div>
        </div>
    }

    request.history.sort(function(a, b) {
        return new Date(b.date) - new Date(a.date);
    });

    if( isExpanded ) {
        return(
            <div className="we-request__histories">
                {historyEntryDetail}
                <div className="we-history__entries">
                {historyEntries.length > 0 ? 
                    historyEntries.map((history, index) => 
                    <div key={`REQUEST_HISTORY_${index}`} className="we-history">
                            <p><small>Datum</small>{history.date ? formatDate(history.date) : ""}</p><div className="we-request__summary">
                            <p>
                                <small>Status</small>
                                {history.title ? history.title : "-"}
                            </p>
                            </div>
                            <div className="we-request__contact-person">
                                <p>
                                    <small>Kontakter</small>
                                    {history.contactPerson ? history.contactPerson : "-"}
                                </p>
                            </div>
                            <div className="we-request__history-action">
                                <p>
                                    <small>&nbsp;</small>
                                    <button onClick={() => setSelectedHistoryEntry(history, ToggleModal( true ))}>Details</button>
                                    <button className="we-history__delete-btn" onClick={() => handleDeleteHistoryEntry(history.id)}>x</button>
                                </p>
                            </div>
                    </div> )
                : ""}
                </div>
                <form
                    id={`history_form_${request.id}`}
                    className="we-history__form"
                    onSubmit={addNewHistoryEntry}
                  >
                    <label className="we-input">
                      <input
                        type="datetime-local"
                        name="date"
                        placeholder="Datum"
                        value={currentDate}
                        onChange={(e) => setCurrentDate(e.target.value)}
                      />
                    </label>
                    <label className="we-input">
                      <input
                        type="text"
                        name="contactPerson"
                        value={contactPerson}
                        onChange={e => setContactPerson(e.target.value)}
                        placeholder="Kontakperson"
                      />
                    </label>
                    <label className="we-input">
                      <input
                        type="text"
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                        name="title"
                        placeholder="Status"
                      />
                    </label>
                    <label className="we-input">
                      <textarea name="note" placeholder="Details" value={note} onChange={e => setNote(e.target.value)}/>
                    </label>
                    <div className="we-btn-wrapper">
                        <button type="submit" className="we-btn">Speichern</button>
                    </div>
                  </form>
            </div>
        )
    } else {
        return(
            <div className="we-request__histories">
                {historyEntryDetail}
                {(request.history && request.history.length > 0) ? <p className="we-request__small-history">{request.history[0].date ? `${dayDifferenceBetweenDates(request.history[0].date) === 0 ? "heute:" : `vor ${dayDifferenceBetweenDates(request.history[0].date)} ${dayDifferenceBetweenDates(request.history[0].date) > 1 ? "Tagen:" : "Tag:"}` } ${(request.history[0] && request.history[0].title) ? request.history[0].title : "-"} ${(request.history[0] && request.history[0].contactPerson) ? `(${request.history[0].contactPerson})` : ""}` : ""}</p> : ""}
                
            </div>
        )
    }
    
}

function AssignTeamModal({teams, onAssignTeamHandler, setIsAssignModalOpen, requestTeam}) {
    const [selectedTeam, setSelectedTeam] = useState(requestTeam ? requestTeam : "");
 
    return(
        <div className="we-assign-team-modal">
            <button className="we-assign-team-modal__wrapper" aria-label="Schließen" onClick={() => setIsAssignModalOpen(false)}>&nbsp;</button>
            <div className="we-assign-team-modal__content">
                <button className="we-assign-team-modal__close-btn" aria-label="Schließen" onClick={() => setIsAssignModalOpen(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                </button>
                <p>An welches Team soll übergeben werden?</p>
                <WeSelect2 onChangeHandler={(e) => setSelectedTeam(e.target.value)} value={selectedTeam}>
                    {teams ? teams.map(team => <option key={`TEAM_${team.id}`} value={team.id}>{team.name}</option>) : ""}
                </WeSelect2>
                <button className="we-btn we-btn--white" onClick={() => onAssignTeamHandler(selectedTeam)}>an Team übergeben</button>
            </div>
        </div>
    )
}

export default function Request({request, selectRequestStatusHandler, users, teams, index, user, updateRequest, deleteRequestHandler}) {
    const [isExpanded, setIsExpanded] = useState(false);
    const [topic, setTopic] = useState( request.topic ? request.topic : "");
    const [oldTopic, setOldTopic] = useState( request.topic ? request.topic : "");
    const [currentDate, setCurrentDate] = useState(getCurrentDateTime());
    const [isAssignModalOpen, setIsAssignModalOpen] = useState( false );
    var jsonContent      = "";
    var prevExpadnStatus = useRef();
    var companyName      = "";

    // @deprecated since version 2022-04-21
    // @todo remove function after 2022-06-01
    function processFieldValue(field){
    	/**
    	 * process field value depending of the fields type
    	 * @author René Görlitz <rene.goerlitz@ws-epic.de>
    	 * @version 2022-04-05
    	 * @param {Object} field 
    	 * @returns {string}
    	 * @since 2022-04-05 type 'file' implemented
    	 * @since 2022-04-05 type 'euro' implemented
    	 * @since 2022-04-04 type 'date' implemented
    	 */
    	var returnValue = field.value;
    	switch(field.type){
    		case 'date':
    			var tmp = field.value.split('-');
    			tmp = tmp.reverse();
    			returnValue = tmp.join('.');
    			break;
    		case 'euro':
    			var neu = '';
    			var p = Math.pow(10, 2);
    			
				returnValue = '' + parseInt(returnValue * p + (.5 * (returnValue > 0 ? 1: -1))) / p;

				// Komma ermittlen
				var idx = returnValue.indexOf('.');

				// fehlende Nullen einfügen
				if(idx !== -1) {
					returnValue += (idx === -1 ? '.': '') + p.toString().substring(1);
				}

				// Nachkommastellen ermittlen
				idx = returnValue.indexOf('.');
				if(idx === -1) idx = returnValue.length;
					else neu = "," + returnValue.substr(idx + 1, 2);

				// Tausendertrennzeichen
				while(idx > 0) {
					if(idx - 3 > 0)
					neu = "." + returnValue.substring(idx - 3, idx) + neu;
						else
							neu = returnValue.substring(0, idx) + neu;
					idx -= 3;
				}
				returnValue = neu + ' €';
    			break;
    		case 'file':
    			returnValue = btoa(returnValue);
    			returnValue = <a href={`download.php?file=${returnValue}`}>Download</a>;
    			break;
    	}
    	return returnValue;
    }
    
    function pad(num, size) {
        num = num.toString();
        while (num.length < size) num = "0" + num;
        return num;
    }

    function getCurrentDateTime() {
        var currentdate = new Date();
        var datetime =
          currentdate.getFullYear() +
          "-" +
          pad(currentdate.getMonth() + 1, 2) +
          "-" +
          pad(currentdate.getDate(), 2) +
          "T" +
          currentdate.getHours() +
          ":" +
          pad(currentdate.getMinutes(), 2 );
        return datetime;
    }

    function onAssignTeamHandler(teamId) {
        API.assignRequestTeam(user.token, request.id, teamId).then(response => {
            if( response.success ) {
                var newRequest = request;
                newRequest.teamId = teamId;
                setIsAssignModalOpen( false, updateRequest(newRequest) );
            }
        });
    }

    function requestUserSelectHandler(userToken, requestId, userId) {
        API.assignRequest(userToken, requestId, userId).then(response => {
            if( response.success ) {
                var newUser = users.filter(user => user.id === parseInt(userId));
                request.user = {
                    id: (newUser[0] && newUser[0].id) ? newUser[0].id : "",
                    firstname: (newUser[0] && newUser[0].firstname) ? newUser[0].firstname : "",
                    lastname: (newUser[0] && newUser[0].lastname) ? newUser[0].lastname : ""
                }
                updateRequest( request );
            }
        });
    }

    function saveNewTopic() {
        API.setTopic(user.token, request.id, topic ).then(response => {
            if( response.success ) {
                setOldTopic( topic );
            }
        });
    }

    function renderJsonContentPattern(fields, pattern) {
        let substrings = pattern.match(/\{.*?\}/gm);
        let replacedPattern = pattern;
        var key = "";
        substrings.map(substring => {
            key = substring.replace("{", "");
            key = key.replace("}", "");
            console.log(fields[key]);
            if( fields[key] && fields[key].hasOwnProperty("value") ) {
                replacedPattern = replacedPattern.replace(substring, processFieldValue(fields[key]));
            }
        });
        replacedPattern.replace("\n", "<br/>");
        return replacedPattern;
    }

    function renderJsonContent(request) {
        var templatePhone = "";
        var templateMail  = "";

        if(request.phone && request.phone !== "") {
            templatePhone = <>{request.phone}<br /></>;
        }
        if(request.email && request.email !== "") {
            templateMail = request.email;
        }

        var templateMainData = 
            <>
                {jsonContent}
                <p className="maindata">
                    {request.firstname + " " + request.lastname}
                    <br />
                    {templatePhone}
                    {templateMail}
                </p>
            </>;

        if( request.json.hasOwnProperty("groups") ) {
            return <>
                {templateMainData}
                <ul>
                    {request.json.groups.map((group, groupIndex) => 
                        <RequestPropertyGroup key={groupIndex} group={group} index={groupIndex} user={user} />
                    )}
                </ul>
            </>;
        } else {
            return <>
                {templateMainData}
                <ul>
                {Object.keys(request.json).map((key, index) => 
                    <RequestPropertyGroup group={request.json[key]} index={key} user={user} />
                    )}
            </ul></>;
        }
    }

    if( request.company && request.company !== "-" ) {
        companyName = request.company;
    } else {
        companyName = `${request.firstname ? request.firstname : ""} ${request.lastname ? request.lastname : ""}`
    }

    if( isExpanded ) {
        if( request.json ) {
            jsonContent = renderJsonContent(request);
        }
    }

    return(
        <>
        {isAssignModalOpen ? <AssignTeamModal teams={teams} onAssignTeamHandler={onAssignTeamHandler} setIsAssignModalOpen={setIsAssignModalOpen} requestTeam={request.teamId}/> : ""}
        <li key={`REQUEST_LIST_ITEM_${index}_${request.id}`} className={`we-request${isExpanded ? " is--expanded" : ""}`}>
            <div className="we-request__project">
                <DropdownMenu>
                    <li>
                        <button onClick={() => deleteRequestHandler(request.id)}>
                            Löschen
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg> 
                        </button>
                    </li>
                    <li>
                        <button onClick={() => setIsAssignModalOpen( !isAssignModalOpen )}>
                            anderes Team
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                        </button>
                    </li>
                </DropdownMenu>
                <button className="we-request__expand-btn"onClick={() => setIsExpanded(!isExpanded, setCurrentDate(getCurrentDateTime()))}>
                    <p><small>{teams.filter(team => team.id  === request.teamId).length > 0 ? teams.filter(team => team.id  === request.teamId)[0].name : ""}</small>{request.project ? request.project : "-"}</p>
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18.367" height="12.012" viewBox="0 0 18.367 12.012">
                            <path id="Pfad_18" data-name="Pfad 18" d="M5931.748,4915.067l6.355,6.355,6.355-6.355" transform="translate(-5928.92 -4912.239)" fill="none" stroke="#18181d" strokeLinecap="round" strokeWidth="4"/>
                        </svg>
                    </span>
                </button>
                {jsonContent}
            </div>
            <div className="we-request__details">
                <div className="we-request__company">
                    <p>{companyName}</p>
                </div>
                <div className="we-request__title">
                    <input type="text" value={topic} onChange={(e) => setTopic(e.target.value)} placeholder="Worum geht's?" />
                    {oldTopic !== topic ? <button className="we-btn-save" onClick={saveNewTopic}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-save"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg></button> : ""}
                </div>
                <div className="we-request__user">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16.476" height="20.594" viewBox="0 0 16.476 20.594">
                        <g id="noun_contact_1950626" transform="translate(0 0)">
                            <g id="Gruppe_24" data-name="Gruppe 24" transform="translate(0 0)">
                            <path id="Pfad_9" data-name="Pfad 9" d="M10.352,285.358a5.663,5.663,0,1,0,5.667,5.662A5.669,5.669,0,0,0,10.352,285.358Zm-5.109,9.967a4.374,4.374,0,0,0-3.126,4.19v5.45a.992.992,0,0,0,.943.987H17.649a.992.992,0,0,0,.943-.987v-5.45a4.377,4.377,0,0,0-3.127-4.19,6.663,6.663,0,0,1-10.222,0Z" transform="translate(-2.116 -285.358)" fill="#fff"/>
                            </g>
                        </g>    
                    </svg>
                    <div className="we-select__wrapper">
                        <select className="we-select" value={(request.user && request.user.id) ? request.user.id : ""} onChange={(e) => requestUserSelectHandler(user.token, request.id, e.target.value)}>    
                            <option key={`REQUEST_${request.id}_USER_0`} value="0" >-</option>
                            {users.filter(user => user.teamIds && user.teamIds.includes(request.teamId)).map(user => <option key={`REQUEST_${request.id}_USER_${user.id}`} value={user.id}>{user.firstname}</option>)}
                        </select>
                        <span className="we-select__arrow">
                            <svg width="12px" height="8px" viewBox="0 0 12 8" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                                    <g transform="translate(-212.000000, -257.000000)" stroke="#FFFFFF" strokeWidth="2">
                                        <g>
                                            <g transform="translate(18.000000, 240.000000)">
                                                <g transform="translate(200.000000, 20.000000) rotate(90.000000) translate(-200.000000, -20.000000) translate(189.000000, 9.000000)">
                                                    <polyline transform="translate(12.000000, 11.000000) rotate(-90.000000) translate(-12.000000, -11.000000) " points="7.712 8.856 12 13.144 16.288 8.856"></polyline>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </span>
                    </div>
                </div>
                <div className={`we-request__status ${request.status.toLowerCase()}`}>
                    <div className="we-select__wrapper">
                        <select className="we-select" onChange={(e) => selectRequestStatusHandler(e, request)} value={request.status}>
                            <option key={`${request.id}_STATUS_OPTION_empty`} value="">-</option>
                            {Object.keys(REQUEST_STATS).map(key => <option key={`${request.id}_STATUS_OPTION_${key}`} value={key}>{REQUEST_STATS[key]}</option>)}
                        </select>
                        <span className="we-select__arrow">
                            <svg width="12px" height="8px" viewBox="0 0 12 8" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                                    <g transform="translate(-212.000000, -257.000000)" stroke="#FFFFFF" strokeWidth="2">
                                        <g>
                                            <g transform="translate(18.000000, 240.000000)">
                                                <g transform="translate(200.000000, 20.000000) rotate(90.000000) translate(-200.000000, -20.000000) translate(189.000000, 9.000000)">
                                                    <polyline transform="translate(12.000000, 11.000000) rotate(-90.000000) translate(-12.000000, -11.000000) " points="7.712 8.856 12 13.144 16.288 8.856"></polyline>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </span>
                    </div>
                </div>
                <RequestHistory user={user} request={request} isExpanded={isExpanded} updateRequest={updateRequest} currentDate={currentDate} setCurrentDate={setCurrentDate} getCurrentDateTime={getCurrentDateTime} />
            </div>
        </li>
        </>
    )
    
}
