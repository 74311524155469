import { useState, useEffect, useRef } from "react";

export default function DropdownMenu({children}) {
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef(null);
    var onClickOutside = function() {
        if( isOpen ) {
            setIsOpen( false );
        }
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside && onClickOutside();
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
        document.removeEventListener('click', handleClickOutside, true);
        };
    }, [ onClickOutside ]);

    return(
        <div className={`we-dropdown-menu ${isOpen ? "is--open" : ""}`} ref={ref}>
            <button className="we-dropdown-menu__btn" onClick={() => setIsOpen( true )}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
            </button>
            <ul className="we-dropdown-menu__content">
                {children}
            </ul>
        </div>
    )
}