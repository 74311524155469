import { useState, useEffect, useRef } from "react";

export default function WeSelect2({children, onChangeHandler, value}) {
    return(
        <div className="we-select__wrapper">
            <select
                className="we-select"
                required
                value={value}
                onChange={onChangeHandler}
            >
                <option value="">Bitte w&auml;hlen ...</option>
                {children}
            </select>
            <span className="we-select__arrow">
                <svg width="12px" height="8px" viewBox="0 0 12 8" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                        <g transform="translate(-212.000000, -257.000000)" stroke="#FFFFFF" strokeWidth="2">
                            <g>
                                <g transform="translate(18.000000, 240.000000)">
                                    <g transform="translate(200.000000, 20.000000) rotate(90.000000) translate(-200.000000, -20.000000) translate(189.000000, 9.000000)">
                                        <polyline transform="translate(12.000000, 11.000000) rotate(-90.000000) translate(-12.000000, -11.000000) " points="7.712 8.856 12 13.144 16.288 8.856"></polyline>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </span>
        </div>
    )
}