import { useEffect, useState } from "react";
import Requests from "./components/Requests";
import Login from "./components/Login";
import Footer from "./components/Footer";

import API from "./classes/API";
import Loading from "./components/Loading";


function App() {
  const [user, setUser] = useState( null );
  const [isLoading, setIsLoading] = useState( true );

  function loginHandler(response) {
    if( !response ) { return; }
    if( response.success ) {
      setUser({token: response.token, id: response.uid, img: response.img, firstname: response.firstname, lastname: response.lastname });
    }
  }

  function logout() {
    API.logout();
    setUser(null);
  }

  useEffect(function() {
    if( isLoading ) {
      var storageUser = API.getLocalStorageUser();
      if( !storageUser ) {
        setIsLoading(false);
      } else {
        setUser( storageUser , setIsLoading( false ) );
      }
    }
  },[isLoading])

  if( isLoading ) {
    return( <Loading />);
  }


  if( !isLoading && user === null ) {
    return (
      <>
        <Login loginHandler={loginHandler} />
        <Footer />
      </>
    );
  }
  return (
    <>
      <nav className="we-nav">
        <div className="wrapper">
          <p>WE-Funnel</p>
          <button className="we-logout-btn" onClick={logout} aria-label="Logout">
            Logout
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
          </button>
        </div>
      </nav>
      <Requests user={user} />
      <Footer />
    </>
  );


  
}

export default App;
