import { useState} from "react";

const DEBUG = true;

function processFieldValue(field, user){
    /**
     * process field value depending of the fields type
     * @author René Görlitz <rene.goerlitz@ws-epic.de>
     * @version 2022-07-25
     * @param {Object} field 
     * @returns {string}
     * @since 2022-07-25 property group doesn't collapse anymore when clicking the content
     * @since 2022-07-19 file is downloadable in frontend
     * @since 2022-04-05 type 'file' implemented
     * @since 2022-04-05 type 'euro' implemented
     * @since 2022-04-04 type 'date' implemented
     */
    if(DEBUG){console.log('function processFieldValue ...');}
    if(DEBUG){console.log('... field:');}if(DEBUG){console.log(field);}
    
    var returnValue = field.value;
    
    if(returnValue){
    
	    switch(field.type){
    	    case 'date':
       	 		var tmp = field.value.split('-');
       	 		tmp = tmp.reverse();
       	 		returnValue = tmp.join('.');
        		break;
        	case 'euro':
            	var neu = '';
            	var p = Math.pow(10, 2);
            
            	returnValue = '' + parseInt(returnValue * p + (.5 * (returnValue > 0 ? 1: -1))) / p;

            	// Komma ermittlen
            	var idx = returnValue.indexOf('.');

            	// fehlende Nullen einfügen
            	if(idx !== -1) {
                	returnValue += (idx === -1 ? '.': '') + p.toString().substring(1);
            	}

            	// Nachkommastellen ermittlen
            	idx = returnValue.indexOf('.');
            	if(idx === -1) idx = returnValue.length;
                	else neu = "," + returnValue.substr(idx + 1, 2);

            	// Tausendertrennzeichen
            	while(idx > 0) {
                	if(idx - 3 > 0)
                		neu = "." + returnValue.substring(idx - 3, idx) + neu;
                    		else
                        		neu = returnValue.substring(0, idx) + neu;
                	idx -= 3;
            	}
            	returnValue = neu + ' €';
            	break;
        	case 'file':
            	returnValue = btoa(returnValue);
            	returnValue = <a href={`api/v1.0.0/download.php?file=${returnValue}&token=${user.token}`}>Download</a>;
            	break;
    	}    
    
    } else {
    	// nothing to do
    }
    return returnValue;
}

export default function RequestPropertyGroup({group, index, user}){
    /**
     * returning a property group and its values
     * @author  René Görlitz <rene.goerlitz@ws-epic.de>
     * @version 2022-07-19
     * @param   {object} group - complete group object or value to be shown
     * @param   {string} index - just an index or title to be shown
     * @returns {component}
     * @since 2022-07-19 user is available now
     * @since 2022-04-21 processing value types
     */
    if(DEBUG){console.log('function RequestPropertyGroup ...');}
    var title  = "";
    var values = [];
    const [ isClosed, setCloseProperty] = useState(true);

    if(DEBUG){console.log('... param - group:');console.log(group);}
    if(DEBUG){console.log('... param - index:');console.log(index);}
    if(DEBUG){console.log('... param - user:');console.log(user);}

    if(group.label){
        if(DEBUG){console.log('... group mode identified');}
        title  = group.label;
        if(group.fields){
            if(DEBUG){console.log('... fields found');}
            if(group.pattern){
                var valueNew = group.pattern;
                if(DEBUG){console.log('... with pattern');}
                for (const key in group.fields) {
                    if (Object.hasOwnProperty.call(group.fields, key)){
                        var value = processFieldValue(group.fields[key], user);
                        if(DEBUG){console.log('... replacing '+key+' with '+value);}
                        if(DEBUG){console.log('... val before: '+valueNew);}
                        valueNew = valueNew.replace("{"+key+"}", value);
                        if(DEBUG){console.log('... val after: '+valueNew);}
                    }
                }
                values.push({
                    value: valueNew
                });
            } else {
                if(DEBUG){console.log('... without pattern');}
                for (const key in group.fields) {
                    var value = processFieldValue(group.fields[key], user);
                    if (Object.hasOwnProperty.call(group.fields, key)) {
                        values.push({
                              label: group.fields[key].label
                            , value: value
                            , key  : key
                        });
                    }
                }
            }           
            if(DEBUG){console.log(values);}
        }
    } else {
        if(DEBUG){console.log('... key-value mode identified');}
        title = index;
        values.push({value:group});
    }
    if(group.key){
        title  = group.key;
        values = 
            [{value:group.value}];
    }
    return(
        <li 
         className={`wef-property-group${isClosed ? " is--closed" : ""}`}>
            <span 
             key={index}
             onClick  = {()=>{setCloseProperty(!isClosed)}}>
                {title}
                <span 
                 onClick  = {()=>{setCloseProperty(!isClosed)}}
                 className="iconExpand">&gt;</span>
            </span>
            <ul>
                {values.map((value, j) =>
                        <li key={j}> 
                            <b>{value.label}</b>
                            <p>{value.value}</p>
                        </li>    
                    )}
            </ul>
        </li>
    )
}
