var md5 = require('md5');

var API = (function(module) {
    //const API_URL = "http://localhost:8080/api/v1.0.0/";
    const API_URL = "https://funnel.ws-epic.de/api/v1.0.0/";
    //const API_URL = "https://showroom.ws-epic.de/we-anfragen/api/v1.0.0/";
    //const API_URL = "http://funnel.gerling-schulz-lueders.de/app/api/v1.0.0/";

    const LOCAL_STORAGE_USER = "WE_FUNNEL_USER";

    function setLocalStorageUser( user = null ) {
        localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(user));
    }

    function getLocalStorageUser() {
        var storageUser = localStorage.getItem(LOCAL_STORAGE_USER);
        if( !storageUser ) {
            return storageUser;
        }
        storageUser = JSON.parse(storageUser);
        storageUser.id = parseInt(storageUser.id);
        return storageUser;
    }

    function removeLocalStorageUser() {
        localStorage.removeItem(LOCAL_STORAGE_USER);
    }

    function getRequests(token = null, filterTeamId = null, filterUserId = null, filterStatus = null, filterProject = null ) {
        if( !token ) { return {status:"400", data: null}; }
        return fetch(API_URL, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "POST",
            body: `task=getRequests&token=${token}${filterTeamId ? `&filterTeamId=${filterTeamId}` : ""}${filterUserId ? `&filterUserId=${filterUserId}` : ""}${filterStatus ? `&filterStatus=${filterStatus}` : ""}${filterProject ? `&filterProject=${filterProject}` : ""}`,
          })
            .then((response) => response.json())
            .then((data) => {
              return data;
        });
    }

    function deleteRequest(token = null, reuqestId = null ) {
      if( !token || !reuqestId ) { return {status:"400", data: null}; }
      return fetch(API_URL, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          method: "POST",
          body: `task=deleteRequest&token=${token}${reuqestId ? `&id=${reuqestId}` : ""}`,
        })
          .then((response) => response.json())
          .then((data) => {
            return data;
      });
  }

    function updateRequestStatus( token = null, request = null ) {
      if( !token || !request ) { return {success:false}; }
      return fetch(API_URL, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        method: "POST",
        body:
          "task=updateRequestStatus&toke="+token+"&id=" +
          request.id +
          "&status=" +
          request.status,
      })
        .then((response) => response.json())
        .then((data) => {return data} );
    }

    function assignRequest( token = null, requestId = null, userId = null ) {
      if( !token || !requestId || !userId) { return {success:false}; }
      return fetch(API_URL, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        method: "POST",
        body:
          "task=assignRequest&token="+token+"&userId=" +
          userId +
          "&requestId=" +
          requestId,
      })
        .then((response) => response.json())
        .then((data) => {return data} );
    }

    function assignRequestTeam( token = null, requestId = null, teamId = null ) {
      if( !token || !requestId || !teamId) { return {success:false}; }
      return fetch(API_URL, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        method: "POST",
        body:
          "task=assignRequestTeam&token="+token+"&teamId=" +
          teamId +
          "&requestId=" +
          requestId,
      })
        .then((response) => response.json())
        .then((data) => {return data} );
    }

    function setTopic( token = null, requestId = null, topic = null ) {
      return fetch(API_URL, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        method: "POST",
        body:
          "task=setTopic&token="+token+"&topic=" +
          topic +
          "&requestId=" +
          requestId,
      })
        .then((response) => response.json())
        .then((data) => {return data} );
    }

    function deleteHistory( token = null, historyId = null ) {
      return fetch(API_URL, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        method: "POST",
        body:
          "task=deleteHistory&token="+token+"&historyId="+historyId,
      })
        .then((response) => response.json())
        .then((data) => {return data} );
    }

    function addNewRequestHistoryEntry(token = null, requestId = null, date = null, contactPerson = null, title = null, note = null) {
      return fetch(API_URL, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        method: "POST",
        body:
        `task=setHistory&token=${token}${date ? `&requestId=${requestId}` : ""}${contactPerson ? `&contactPerson=${contactPerson}` : ""}${date ? `&date=${date}` : ""}${title ? `&title=${title}` : ""}${note ? `&note=${note}` : ""}`
        ,
      })
        .then((response) => response.json())
        .then((data) => {return data} );
    }

    function getUsers( token = null ) {
      if( !token ) { return {success:false, users: []}; }
      return fetch(API_URL, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          method: "POST",
          body: "task=getUsers&token="+token,
        })
          .then((response) => response.json())
          .then((data) => {
            return data;
      });
    }

    function getTeams( token = null ) {
      if( !token ) { return {success:false, users: []}; }
      return fetch(API_URL, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          method: "POST",
          body: "task=getTeams&token="+token,
        })
          .then((response) => response.json())
          .then((data) => {
            return data;
      });
    }

    function userLogin(username, password, googleIdToken, googleLogin = true) {
        return fetch(API_URL, {
          method: "POST",
          //credentials: "include",
          referrerPolicy: 'no-referrer',
          // AkpUP19
          body: `task=login${username !== "" ? "&uname=" + username : ""}${password !== "" ? "&pwd=" + md5(password) : ""}${googleIdToken !== "" ? "&googleIdToken=" + googleIdToken : ""}`,
          headers: new Headers(
            { "content-type": "application/x-www-form-urlencoded" })
        }).then((response) => {
          return response.json();
          // do something awesome that makes the world a better place
        }).then((data) => {
          if (data.success && data.token) {
              setLocalStorageUser({token: data.token, id: data.uid, img: data.img, firstname: data.firstname });
          } 
          return data;
        });
    }

    function logout() {
        removeLocalStorageUser();
    }

    window.logout = logout;

    module.getRequests = getRequests;
    module.userLogin = userLogin;
    module.logout = logout;
    module.getLocalStorageUser = getLocalStorageUser;
    module.getUsers = getUsers;
    module.updateRequestStatus = updateRequestStatus;
    module.assignRequest = assignRequest;
    module.addNewRequestHistoryEntry = addNewRequestHistoryEntry;
    module.deleteRequest = deleteRequest;
    module.setTopic = setTopic;
    module.deleteHistory = deleteHistory;
    module.getTeams = getTeams;
    module.assignRequestTeam = assignRequestTeam;

    return module;

})(API || {});


export default API;
